const HIDE_PIGEONHOLE_REACTIONS_FOR_PLAYER_BUTTONS = [
  'subsCapsButton',
  'audioTrackButton',
];

function getViewerName (samlAttributes) {
  const {
    nameID,
    fname,
    lname,
  } = samlAttributes;

  let name = '';
  if (fname || lname) {
    // "fname" and "lname" will be defined only if the mapping is configured for SAML attributes
    // customers need to configure it if they want to properly identify viewer's name
    name = lname ? `${fname} ${lname}` : fname;
  } else {
    // otherwise, the fallbacks keys will be used

    const samlKeys = Object.keys(samlAttributes);
    const nameKey = samlKeys.find(key => key.endsWith('/name')); // 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
    const givennameKey = samlKeys.find(key => key.endsWith('/givenname')); // 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
    const surnameKey = samlKeys.find(key => key.endsWith('/surname')); // 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
    const nicknameKey = samlKeys.find(key => key.endsWith('/nickname')); // 'http://schemas.auth0.com/nickname'

    if (nameKey) {
      name = samlAttributes[nameKey];
    } else if (givennameKey || surnameKey) {
      name = samlAttributes[surnameKey] ? `${samlAttributes[givennameKey]} ${samlAttributes[surnameKey]}` : samlAttributes[givennameKey];
    } else if (nicknameKey) {
      name = samlAttributes[nicknameKey];
    } else {
      name = nameID;
    }
  }

  return name;
}

function observePlayerControlBarChanges ({ playerElement, onShow, onHide }) {
  const observer = new MutationObserver(mutationsList => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' || mutation.type === 'attributes') {
        const menuElement = document.querySelector('.vjs-menu.vjs-lock-showing');
        const isVideoPlayingInactive = document.querySelector('.vjs-playing.vjs-user-inactive');

        if (!menuElement || isVideoPlayingInactive) {
          onShow();
        } else {
          onHide();
        }
      }
    }
  });

  observer.observe(playerElement, {
    attributes: true,
    childList: true,
    subtree: true,
  });
}

module.exports = {
  getViewerName,
  observePlayerControlBarChanges,
  HIDE_PIGEONHOLE_REACTIONS_FOR_PLAYER_BUTTONS,
};
