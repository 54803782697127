const $ = require('jquery');
const _ = require('underscore');
const moment = require('moment');
const BCImageLoader = require('bc-image-loader');
const { onPlayerSetup } = require('../../common/js/perform');
const { videoFinisher } = require('../../common/js/videofinisher');
const { updateTextSize } = require('../../common/js/fontResizer');
const { nextVideo } = require('../../common/js/utils');
const { getPigeonholeTag } = require('../../common/js/pigeonhole/Pigeonhole');
const {
  observePlayerControlBarChanges,
  HIDE_PIGEONHOLE_REACTIONS_FOR_PLAYER_BUTTONS,
} = require('../../common/js/pigeonhole/pigeonhole-utils');

require('slick-carousel');

// register Additional Time-related Handlebars helpers
require('../../../../shared/lib/handlebarsHelpersTime')();

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();

//used for rotating elements - typically ui arrows
$.fn.extend({
  bcSuperSpin: function (fromAngle, toAngle, duration) {
    return this.each(function () {
      const $this = $(this);

      $({ deg: fromAngle }).animate({ deg: toAngle }, {
        duration: duration,
        step: function (now) {
          $this.css({
            transform: 'rotate(' + now + 'deg)',
          });
        },
      });
    });
  },
});

class CategoriesNav {
  init () {
    this.desktopDropdown();
    this.mobileDropdown();
  }

  desktopDropdown () {
    const slideDuration = 400;
    $('section.categories-list .categories-list-inner > ul.categories > li.category-dropdown').each((key, value) => {
      const $this = $(value);
      let dropdownBox;
      let innerList;
      let carret;
      $this.hover(function () {
        if (bcLiveEvent.windowWidth() > bcLiveEvent.MOBILE) {
          dropdownBox = $this.children('.dropdown-box');
          innerList = $(dropdownBox).children('ul.dropdown-inner');
          carret = $(dropdownBox).siblings('a').children('i.fa');
          dropdownBox.stop().fadeIn(slideDuration);
          innerList.stop().slideDown(slideDuration);
          carret.bcSuperSpin(0, 180, 300);
        }
      }, function () {
        if (bcLiveEvent.windowWidth() > bcLiveEvent.MOBILE) {
          dropdownBox.stop().fadeOut(slideDuration);
          innerList.stop().slideUp(slideDuration);
          carret.bcSuperSpin(180, 0, 300);
        }
      });
    });
  }

  mobileDropdown () {
    const fullList = $('section.categories-list .categories-list-inner > ul.categories');
    const clickableTitle = $('section.categories-list .categories-list-inner .mobile-current-title');
    const carret = clickableTitle.children('i.fa');
    clickableTitle.click(function () {
      if (fullList.hasClass('active')) {
        fullList.slideUp();
        carret.bcSuperSpin(180, 0, 300);
        fullList.removeClass('active');
      } else {
        fullList.slideDown();
        carret.bcSuperSpin(0, 180, 300);
        fullList.addClass('active');
      }
    });
  }
}

class Detail {
  init () {
    // Disqus rendering has a problem with increasing the height drastically of
    // its own content when rendered within a hidden elements so we start comments
    // explicitly visible - see onDisqusReady
    this.setCommentsVisible(true, true);
    this.mq = 320;
    this.isCommentsVisible = true;
    this.READMORE_HEIGHT = 70;

    $('.bc-read-more-container-mobile').on('click', () => {
      const $description = $('.video-detail-description');
      const $relatedLink = $('.video-metadata-mobile .related-link');
      $relatedLink.show();
      const $relatedLinkHeight = $relatedLink.prop('scrollHeight') || 0;
      const $textHeight = $('.video-detail-text').prop('scrollHeight');
      $description.height($textHeight + $relatedLinkHeight);
      $description.css('-webkit-line-clamp', 'unset');
      $('.bc-read-more-container-mobile').toggle(false);
      $('.bc-read-less-container-mobile').toggle(true);
    });

    $('.bc-read-less-container-mobile').on('click', () => {
      const $description = $('.video-detail-description');
      const $relatedLink = $('.video-metadata-mobile .related-link');
      $relatedLink.hide();
      $description.height(this.READMORE_HEIGHT + 'px');
      $description.css({ '-webkit-line-clamp': '3' });
      $('.bc-read-more-container-mobile').toggle(true);
      $('.bc-read-less-container-mobile').toggle(false);
    });
    this.updateReadMore();
    $('.view-comments-button').click( () => {
      this.setCommentsVisible(!this.isCommentsVisible);
    });
  }

  onDisqusReady () {
    setTimeout( () => {
      // once Disqus is ready, we still need to wait before re-hiding
      // so that the height can internally arrange itself properly
      this.setCommentsVisible(false, true);
    }, 500);
  }

  setCommentsVisible (isVisible, skipAnimation) {
    const $comments = $('#bc-video-comments');
    const $button = $('.view-comments-button');

    this.isCommentsVisible = isVisible;

    const duration = skipAnimation ? 0 : 200;
    if (this.isCommentsVisible) {
      $button.addClass('active');
      $comments.addClass('active');
      $comments.slideDown(duration);
    } else {
      $button.removeClass('active');
      $comments.removeClass('active');
      $comments.slideUp(duration);
    }
  }
  //Read More/Less Buttons
  updateReadMore () {
    const $description = $('.video-detail-description');
    const showReadMore = $description.prop('scrollHeight') > $description.height();
    let maxHeight;
    if (!bcLiveEvent.isMobile()) {
      // disable the buttons on desktop and show full length.
      const $description = $('.video-detail-description');
      $description.css({ display: 'block', height: 'auto' });
      $('.bc-read-more-container-mobile').toggle(false);
      $('.bc-read-less-container-mobile').toggle(false);
      return;
    } else {
      maxHeight = this.READMORE_HEIGHT;
      const showReadLess = !showReadMore && $description.height() > maxHeight;
      $('.bc-read-more-container-mobile').toggle(showReadMore);
      $('.bc-read-less-container-mobile').toggle(showReadLess);
      if (!showReadLess && !showReadMore) {
        $description.css({ display: 'block', height: 'auto' });
      }
    }
  }
}

class LiveDetail {
  init () {
    setTimeout(() => {
      this.adjustChatrollWidth();
    }, 100);
  }

  adjustChatrollWidth () {
    this.adjustLeftRightWidth($('#live-during-chat-and-cta'), $('#le-chatroll'), $('#live-during-cta'));
  }

  adjustVideoDetailWidth () {
    this.adjustLeftRightWidth($('#video-detail-left-right-wrapper'), $('#video-detail-left'), $('#video-detail-right'));
  }

  adjustLeftRightWidth ($container, $left, $right) {
    // if we are on mobile, always go 100% width
    if (bcLiveEvent.mq < 768) {
      $left.width('100%');
      return;
    }

    // depending on the prescence of 'right', we adjust
    // the width of 'left' accordingly
    const availableWidth = $container.width();
    let rightWidth = 0;

    if ($right.length > 0) {
      // add 20px padding between left and right
      rightWidth = ($right.width() + 20);
    }

    $left.width(availableWidth - rightWidth);
  }
}

class LiveEvent {
  init () {
    this.EVENT_DETAILS = global.liveEventDetails; // created as global by layout.hbs
    this.MOBILE = 767;
    this.TABLET = 600;
    this.DESKTOP = 992;
    this.DESKTOP_MAX = 1200;
    this.MAIN_HEADER_HEIGHT = 50;
    this.VIDEO_ASPECT_RATIO = {
      w: 16,
      h: 9,
    };
    this.VIDEOS_PER_PAGE = 12;
    this.PIGEONHOLE_CHAT_CLASS = 'pigeonhole-chat';
    this.PIGEONHOLE_CHAT_VISIBLE_CLASS = 'pigeonhole-chat--visible';
    this.PIGEONHOLE_MOBILE_HEIGHT = 400;
    this.scrollbarWidth = 0;
    this.resizeTimeout = 0;
    this.isTouch = false;
    this.handyState = false;
    this.mq = 320;
    this.adjustedThreshold = 0; //for handyVideoControl
    this.$elementsThatObey = $('.obey-aspect-ratio');
    this.$elementsThatMimic = $('section.hero-wrap .live-hero img');
    this.$parentOfMimicer = this.$elementsThatMimic.parent();
    this.$mimicWrapper = $('section.hero-wrap');
    this.$handyVideoElement = $('.handy-video');
    this.handyVideoConfig = {};
    this.LIVE_STATUS_UPDATE_INTERVAL = 60 * 1000; // 1 minute

    // Get initial status of Live Event from the server
    const site = window.bcGallery.filteredAssemblerData.site;
    this.currentLiveStatus = site && site.indexPageReplace;
    if (!site.isEdit) {
      if (this.currentLiveStatus !== 'index-post') {
        setInterval(this.updateLiveStatus.bind(this), this.LIVE_STATUS_UPDATE_INTERVAL);
      }
    }

    this.setMq();

    this.touchDetect();

    this.obeyAspectRatio(this.$elementsThatObey);

    this.resizeVisualElementsOnSocialPanelUpdate();

    this.listenForImageLoads();
    this.mimicBackgroundCover(this.$elementsThatMimic, this.$parentOfMimicer, this.$mimicWrapper);

    this.imageLoader = new BCImageLoader({
      transcoder: global.BCLS.imageTranscoder,
    });

    // always load images in 16:9 ratio, helps IE 11
    this.imageLoader.ignoreHeight = true;

    // don't run this if no element is found
    if (this.$handyVideoElement.length > 0) {
      this.handyVideoConfig = {
        element: this.$handyVideoElement,
        threshold: .8, //'percent' from top of video before transition happens
        originalVideoInfo: $('.live-event-now-wrap .live-event-now-inner'),
      };
      this.handyVideoConfig.videoInfo = this.handyVideoConfig.originalVideoInfo.clone().addClass('handy-info').hide();
      this.handyVideoConfig.originalVideoInfo.parent().append(this.handyVideoConfig.videoInfo);
      this.handyVideoControl();
    }

    onPlayerSetup( (player) => {
      if (window.site.indexPageReplace === 'index-live') {
        // when in live mode, start playback regardless of if player is set to auto-start or not.
        player.autoplay(true);
        this.executeOnResize();
      }

      this.initPigeonholeReactions();
      this.initPigeonholeChat();
    });

    this.shareButtonDropdown();

    $(window).resize(() => {
      this.executeOnResize();
    });

    this.adjustMobileHomepageLabels();

    this.checkForEmptyChatCtaDiv();

    $('.bc-share-mobile').on('shown.bs.modal', this.styleBackdrop);
    $('.bc-sort-videos-mobile').on('shown.bs.modal', this.styleBackdrop);
    $('.bc-load-more-button').on('click', this.loadMoreVideos.bind(this));
  }

  isMobile () {
    return bcLiveEvent.mq === 320;
  }

  resizeVisualElementsOnSocialPanelUpdate () {
    const widgetContainer = $('#social-panel');
    if (widgetContainer) {
      widgetContainer.on('transitionend webkitTransitionEnd oTransitionEnd', () => {
        bcLiveEvent.executeOnResize();
      });
    }
  }

  checkForEmptyChatCtaDiv () {
    const ctaDiv = $('#live-during-chat-and-cta');
    if (ctaDiv.height() === 0) {
      //no content, remove the divider
      $('.under-hero').hide();
    }
  }
  //refresh page if page status changes. Status codes in below comment for reference:
  /*
    'index': 'Home: Pre-Event',
    'index-live': 'Home: Live',
    'index-post': 'Home: Post-Event'
  */
  updateLiveStatus () {
    this.getLiveStatus( (liveStatus) => {
      if (liveStatus && liveStatus !== this.currentLiveStatus) {
        window.location.reload();
      }
    });
  }

  getLiveStatus (done) {
    const site = window.bcGallery.filteredAssemblerData.site;
    const liveEventStatusURL = site && site.dynamicCustom && site.dynamicCustom.liveEventStatusURL;
    $.ajax(liveEventStatusURL, {
      dataType: 'json',
      success: function (data) {
        if (data) {
          const status = data.result ? data.result.status : data.status;
          done(status);
        }
        else {
          if (done) {
            done('');
          }
        }
      },
      error: function () {
        console.warn('Cannot fetch live status from S3, trying direct API');
        $.ajax(window.baseUrl + '/api/liveEventStatus', {
          dataType: 'json',
          success: function (data) {
            if (data.result) {
              done(data.result);
            }
          },
        });
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  executeOnResize () {
    this.setMq();
    bcSocialPanel.configurePanels();
    this.obeyAspectRatio(this.$elementsThatObey);

    this.checkForOverlap();
    this.adjustMobileHomepageLabels();

    if (this.$handyVideoElement.length > 0) {
      this.handyVideoAdjustThresholdOnResize();
    }

    bcLiveDetail.adjustChatrollWidth();
    bcLiveDetail.adjustVideoDetailWidth();
    this.mimicBackgroundCover(this.$elementsThatMimic, this.$parentOfMimicer, this.$mimicWrapper);
    this.setThumbnailHeight();

    if (bcLiveEvent.isMobile() && $('#social-panel').width() === 0) {
      $('#social-panel').css('width', '100%');
    }

    bcCarousel.onWindowResize();
  }

  initPigeonholeChat () {
    const liveVideo = window.bcGallery?.filteredAssemblerData?.videoLive;
    const isVideoDetailPage = !!$('.video-detail-wrapper').length;
    const iframeHtml = getPigeonholeTag(liveVideo);

    if (!iframeHtml || isVideoDetailPage) {
      this.removePigeonholeChat();
      return;
    }

    const $chatContainer = $(`.video-and-pigeonhole-container .${this.PIGEONHOLE_CHAT_CLASS}`);

    if ($chatContainer.length) {
      $chatContainer.append(iframeHtml);
      $chatContainer.addClass(this.PIGEONHOLE_CHAT_VISIBLE_CLASS);

      const $videoContainer = $('.video-detail .video-detail-inner');
      $videoContainer.css({ maxWidth: 1124 });
    }
  }

  removePigeonholeChat () {
    const $chatContainer = $(`.video-and-pigeonhole-container .${this.PIGEONHOLE_CHAT_CLASS}`);

    $chatContainer.empty();
    $chatContainer.removeClass(this.PIGEONHOLE_CHAT_VISIBLE_CLASS);

    const $videoContainer = $('.video-detail .video-detail-inner');
    $videoContainer.css({ maxWidth: 816 });
  }

  initPigeonholeReactions () {
    const videoPlayer = window?.videojs?.players?.performPlayer;

    if (videoPlayer) {
      const reactionsContainer = $('.pigeonhole-reactions-iframe');

      const showPigeonholeReactions = () => reactionsContainer.show();
      const hidePigeonholeReactions = () => reactionsContainer.hide();
      const togglePigeonholeReactions = () =>
        reactionsContainer.is(':hidden')
          ? showPigeonholeReactions()
          : hidePigeonholeReactions();

      const hasTouchSupport = navigator.maxTouchPoints > 0;
      const playerElement = videoPlayer?.el_;

      if (hasTouchSupport && playerElement) {
        observePlayerControlBarChanges({
          playerElement,
          onShow: showPigeonholeReactions,
          onHide: hidePigeonholeReactions,
        });
      } else {
        const controlBar = $(videoPlayer.controlBar.el_);

        controlBar.on('mouseenter', hidePigeonholeReactions);
        controlBar.on('mouseleave', showPigeonholeReactions);

        HIDE_PIGEONHOLE_REACTIONS_FOR_PLAYER_BUTTONS.forEach(buttonName => {
          videoPlayer.controlBar[buttonName].on('click', togglePigeonholeReactions);
        });
      }
    }
  }

  setThumbnailHeight () {
    const $thumbnail = $('.video-grid-item .bc-image-loader');
    const width = $thumbnail.width();
    const height = width * this.VIDEO_ASPECT_RATIO.h / this.VIDEO_ASPECT_RATIO.w;
    $thumbnail.css({ 'height': height } );
  }

  setMq () {
    const mq = $('body').css('z-index');
    this.mq = parseInt(mq, 10);
  }

  touchDetect () {
    //sets the isTouchDevice variable appropriately
    this.isTouch = typeof document.ontouchstart !== 'undefined';
  }

  determineIsStudio () {
    // bc-studio class added via page editor
    return $('body').hasClass('bc-studio');
  }

  checkForOverlap () {
    const ele1 = document.getElementById('live-event-now-inner-info');
    const ele2 = document.getElementById('gallerylink');

    if (ele1 && ele2) {
      $('#eventvideotitle').css('display', '');
      $('#gallerylink').css('line-height', '');
      let rect1 = ele1.getBoundingClientRect();
      let rect2 = ele2.getBoundingClientRect();
      let isOverlapping = !(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom);
      if (isOverlapping) {
        //we want to hide the event title in this case
        $('#eventvideotitle').css('display', 'none');
      }
      //recalculate bounding boxes in case we hid the event title, if we're still overlapping we need to move the gallery link down
      rect1 = ele1.getBoundingClientRect();
      rect2 = ele2.getBoundingClientRect();
      isOverlapping = !(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom);
      if (isOverlapping) {
        //we want to move the link down so it does not overlap the 'happening now' text
        $('#gallerylink').css('line-height', '3.2em');
      }
    }
  }

  adjustMobileHomepageLabels () {
    const $label = $('.remindme-wrap .remindme-label');
    const $buttons = $('.remindme-wrap .remindme-buttons');

    $label.removeAttr('style');
    $buttons.removeAttr('style');

    if (this.mq <= 767) {
      $label.css({ position: 'relative', top: -$buttons.height() });
      $buttons.css({ position: 'relative', top: $label.outerHeight() });
    }
  }

  styledHeight ($element) {
    let styledHeight = $element.height();
    styledHeight += parseInt(($element.css('margin-top') || '0').replace('px', ''), 10);
    styledHeight += parseInt(($element.css('margin-bottom') || '0').replace('px', ''), 10);

    return styledHeight;
  }

  obeyAspectRatio (elementsThatObey) {
    //add class obey-aspect-ratio to any div to make it obey the aspect ratio
    elementsThatObey.each((key, value) => {
      const $this = $(value);
      const aspect = this.VIDEO_ASPECT_RATIO;

      if (!$this.hasClass('video-detail-wrapper') && !$this.hasClass('live-video-wrap')) {
        const isMobile = window.innerWidth < this.MOBILE;
        const hasPigeonholeChat = !!$this.find('.pigeonhole-chat').length;
        if (isMobile && hasPigeonholeChat) {
          $this.height(($this.width() * (aspect.h / aspect.w)) + this.PIGEONHOLE_MOBILE_HEIGHT);
        } else {
          $this.height($this.width() * (aspect.h / aspect.w));
        }
        return;
      }

      // reset width first
      $this.width('auto');

      // make exception for handy video
      if ($this.hasClass('handy-video') && $this.hasClass('handy')) {
        $this.height('auto');
        $this.removeAttr('style');
        return;
      }

      // if we got here, we are dealing with a video component in which case we round out
      // to exact 16:9 ratios to prevent black bars
      const startWidth = $this.width();
      const targetWidth = bcLiveEvent.isMobile() ? startWidth : Math.floor((startWidth / aspect.w) * aspect.w);
      let targetHeight = targetWidth / (aspect.w / aspect.h);

      // adjust target width and height to be a clean multiple of the aspect ratio to prevent black borders
      targetHeight = Math.floor((targetHeight / aspect.h) * aspect.h);

      const hasPigeonholeChat = !!$this.find('.pigeonhole-chat').length;
      const isLiveVideoPage = $this.hasClass('live-video-wrap');

      if (isLiveVideoPage && hasPigeonholeChat) {
        const isMobile = window.innerWidth < this.MOBILE;

        if (isMobile) {
          targetHeight += this.PIGEONHOLE_MOBILE_HEIGHT;
        }
      }

      // This is a test checking if we're ok with leaving width to auto
      $this.height(targetHeight);
    });
  }

  mimicBackgroundCover (elementsThatMimic, parentOfMimicer, mimicWrapper) {
    elementsThatMimic.css({ width: 'auto', height: 'auto', marginLeft: '', marginTop: '' });

    const parentDimensions = {
      w: parentOfMimicer.width(),
      h: parentOfMimicer.height(),
      asp: parentOfMimicer.width() / parentOfMimicer.height(),
    };

    const elementDimensions = {
      w: elementsThatMimic.width(),
      h: elementsThatMimic.height(),
      asp: elementsThatMimic.width() / elementsThatMimic.height(),
    };

    // index (AKA index-pre), index-live, index-post
    const activePage = window.site.activePage || 'index';
    // activePage is the suffix of the prop name we need (ex. liveEventPreBackgroundFit_index)
    const backgroundFit = window.site.dynamicCustom['liveEventBackgroundFit_' + activePage] || 'crop';

    switch (backgroundFit) {
      case 'crop':
        this.mimicBackgroundCoverCrop(elementsThatMimic, parentOfMimicer, mimicWrapper, elementDimensions, parentDimensions);
        break;
      case 'maintain':
        this.mimicBackgroundCoverMaintain(elementsThatMimic, parentOfMimicer, mimicWrapper, elementDimensions, parentDimensions);
        break;
      case 'stretch':
        this.mimicBackgroundCoverStretch(elementsThatMimic, parentOfMimicer, mimicWrapper, elementDimensions, parentDimensions);
        break;
      default:
        this.mimicBackgroundCoverCrop(elementsThatMimic, parentOfMimicer, mimicWrapper, elementDimensions, parentDimensions);
    }
  }

  mimicBackgroundCoverCrop (elementsThatMimic, parentOfMimicer, mimicWrapper, elementDimensions, parentDimensions) {
    if (elementDimensions.asp > parentDimensions.asp) {
      //parent taller than image
      elementsThatMimic.css({ width: 'auto', height: elementsThatMimic.parent().height() });
    } else {
      //parent wider than image
      elementsThatMimic.css({ width: elementsThatMimic.parent().width(), height: 'auto' });
    }
    elementsThatMimic.addClass('cropImg');
  }

  mimicBackgroundCoverMaintain (elementsThatMimic, parentOfMimicer, mimicWrapper, elementDimensions, parentDimensions) {
    // background width should always match the parent width
    const bgWidth = parentDimensions.w;
    // find the background height using the aspect ratio
    const bgHeight = bgWidth / elementDimensions.asp;
    // set background height and the wrapper to the same height
    elementsThatMimic.css({ height: bgHeight });
    mimicWrapper.css({ height: bgHeight });
    if (this.isMobile()) {
      const heroHeight = $('.hero-wrap').height();
      $('.live-title').css(({ 'margin-top': heroHeight - 100 }));
    }
  }

  mimicBackgroundCoverStretch (elementsThatMimic) {
    elementsThatMimic.css({ width: '100%', height: '100%', marginLeft: '', marginTop: '' });
  }

  handyVideoAdjustThresholdOnResize () {
    this.adjustedThreshold = this.handyVideoConfig.element.parent().offset().top + (this.handyVideoConfig.threshold * this.handyVideoConfig.element.parent().height()) - $('.main-header').height();
    window.onscroll(); //generate scroll event to re-check handy player state
  }

  handyVideoControl () {
    //false is natural, true is in the destination
    const $theWindow = $(window);
    const child = this.handyVideoConfig.element.children('div');
    this.adjustedThreshold = this.handyVideoConfig.element.offset().top + (this.handyVideoConfig.threshold * this.handyVideoConfig.element.height()) - $('.main-header').height();
    const $navElements = $('.main-header-logo, .main-header-secondary-logo');

    window.onscroll = () => {
      if (this.mq >= 768) {
        if (!this.handyState && $theWindow.scrollTop() >= this.adjustedThreshold) {
          //if PAST the threshold and NOT already in handy state,
          //then transition to handy state
          transition.toDestination(this.handyVideoConfig);
          this.handyState = true;
        } else if (this.handyState && $theWindow.scrollTop() < this.adjustedThreshold) {
          //if BEFORE the threshold and IN handy state,
          //then transition back to original position
          transition.backToOriginal(this.handyVideoConfig);
          this.handyState = false;
        }
      } else if (this.handyState) {
        //Get out of the handy state for mobile view
        transition.backToOriginal(this.handyVideoConfig);
        this.handyState = false;
      }
    };

    const transition = {
      toDestination: (options) => {
        options.element.stop().animate({ opacity: 0 }, 300, () => {
          child.css({ bottom: options.element.height() });
          options.element.addClass('handy');
          bcLiveEvent.obeyAspectRatio(bcLiveEvent.$elementsThatObey);
          child.stop().animate({ bottom: 0 }, 300, () => {
            options.element.addClass('live-event-handy-player');
            options.element.parent().addClass('forceiepaint'); //Force IE to repaint the border we added in line above
            options.element.parent().removeClass('forceiepaint');
          });
          this.checkForOverlap();
        });
        options.originalVideoInfo.stop().animate({ opacity: 0 }, 300);
        options.videoInfo.stop().show(300);
        $navElements.stop().animate({ opacity: 0 }, 300);
      },
      backToOriginal: (options) => {
        options.element.removeClass('live-event-handy-player');
        options.originalVideoInfo.stop().animate({ opacity: 1 }, 300);
        options.videoInfo.stop().hide(300);
        child.stop().animate({ bottom: options.element.height() }, 300, () => {
          options.element.removeClass('handy');
          child.css({ bottom: 0 });
          options.element.stop().animate({ opacity: 1 }, 300);
          $navElements.stop().animate({ opacity: 1 }, 300);
          this.executeOnResize();
          this.checkForOverlap();
        });
      },
    };
  }

  shareButtonDropdown () {
    const $button = $('#remindme-button-share');
    const $dropdown = $('#remindme-shares-dropdown');

    if (!this.isTouch) {
      $button.on('mouseenter', function () {
        $dropdown.stop().hide();
        $dropdown.slideDown();
      });

      $button.on('mouseleave', function () {
        $dropdown.stop().slideUp();
      });

      $dropdown.on('mouseleave', function () {
        $dropdown.stop().slideUp();
      });
    }

    $button.on('click', '> a', function (e) {
      e.preventDefault();

      $button.toggleClass('open');
      if ($button.hasClass('open')) {
        $dropdown.stop().slideUp();
      } else {
        $dropdown.stop().hide();
        $dropdown.slideDown();
      }
    });
  }

  convertForTimezone (timestamp, asString) {
    // parsing the UTC this way gives local date
    const localDate = new Date(timestamp);

    if (asString) {
      // moment makes a local date from UTC
      const dateAsMoment = moment(timestamp);
      let dateAsString = '';
      dateAsString += dateAsMoment.format('MM/DD/YYYY');
      dateAsString += ' ';
      dateAsString += dateAsMoment.format('hh');
      dateAsString += ':';
      dateAsString += dateAsMoment.format('mm');
      dateAsString += ' ';
      dateAsString += dateAsMoment.format('A');
      return dateAsString;
    }

    return localDate;
  }

  twoDigitsPlease (number) {
    return ('0' + number).slice(-2);
  }

  listenForImageLoads () {
    $('.live-hero img').each( () => {
      $(this).on('load', () => {
        this.mimicBackgroundCover(this.$elementsThatMimic, this.$parentOfMimicer, this.$mimicWrapper);
      });
    });
  }

  windowWidth () {
    return $('body').innerWidth();
  }

  styleBackdrop () {
    const $backdrop = $('.modal-backdrop');
    if ($backdrop.length) {
      $('.modal-backdrop').css('opacity','1');
      $('.modal-backdrop').css('background-color','grey');
    }
  }
  loadMoreVideos () {
    const nextPage = $('.bc-load-more-button').data('next-page');
    const match = /page=([a-z0-9]*)/i.exec(nextPage);
    let page;
    if (match && match.length > 0) {
      page = match[1];
    } else {
      page = Math.floor($('.bc-video-grid-items').children().length / this.VIDEOS_PER_PAGE);
    }
    const q = window.query ? window.query.q : null;
    const category = window.category ? window.category.slug : '';
    this.loadVideos({ append: true, page: page, q: q, category: category });
    delete window.query.page;
  }

  loadVideos (params) {
    // reset but not everything so as to not break autoplay next
    if (window.query) {
      window.query.page = null;
      window.query.q = null;
    }

    $('.video-grid').fadeTo(0, 0.5);

    let route = '';

    // recursively loop through categories to find active category
    function recurse (category) {
      if (category.slug === params.category) {
        window.category = category;
        route = '/' + category.slug;
        return;
      }

      if (_.has(category, 'children')) {
        _.each(category.children, function (child) {
          recurse(child);
        });
      }
    }

    if (params.category) {
      _.each(window.categories, function (category) {
        recurse(category);
      });
    }

    if (params.page) {
      window.query.page = params.page;
    }

    if (params.q) {
      window.query.q = params.q;
    }
    if (params.sort_by) {
      window.query.sort_by = params.sort_by;
    }
    return $.ajax(window.baseUrl + '/api/videos' + route, {
      dataType: 'json',
      data: params,
      success: (data) => {
        this.appendVideos(data);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  appendVideos (data) {
    if (!data || !data.result || !data.result.items) {
      return;
    }
    const videos = data.result.items;

    const videoGridItemPath = window.bcGallery.getTemplatePath('/sites/live_event/partials/videoGridItem.hbs');

    bcCarousel.loadTemplate(videoGridItemPath, function (template) {
      for (let i = 0; i < videos.length; i++) {
        if (videos[i]) {
          const contextData = {
            baseUrl: window.baseUrl,
            imageTranscoder: window.BCLS.imageTranscoder,
            category: window.category,
            query: window.query,
            subPath: window.subPath,
          };
          const handlebarContext = _.extend({}, videos[i], contextData);
          $('.video-grid').append(template(handlebarContext));
        }
      }
      bcLiveEvent.imageLoader.loadImages();
      $('.video-grid').fadeTo(0, 1);
      const $loadMoreButton = $('.bc-load-more-button');
      if (data.result.next) {
        const count = data.result.count;
        const totalCount = data.result.totalCount;
        const end = data.result.end || data.result.count;
        let nextPageVideoCount;
        if (totalCount - end < count) {
          nextPageVideoCount = totalCount - end;
        } else if (totalCount !== end) {
          nextPageVideoCount = count;
        }
        $loadMoreButton.text(window.translations['load-more'] +
        ' (' + nextPageVideoCount + ')');
        $loadMoreButton.data('next-page', data.result.next);
      } else {
        $loadMoreButton.hide();
      }
    });
  }
}

class NextVideo {
  init () {
    videoFinisher.onNextVideo(function () {
      // do not autoplay next for live video, but only 'shut off' for homepage
      if ($('body').hasClass('index-live') && window.site.indexPageReplace === 'index-live') {
        return;
      }
      nextVideo();
    });
  }
}

class RemindMe {
  init () {
    $('#remindme-link, #remindme-link-mobile').on('click', (e) => {
      e.preventDefault();

      // we do NOT want this happening when in the studio
      if (!bcLiveEvent.determineIsStudio()) {
        this.downloadReminder();
      }
    });
  }

  downloadReminder () {
    const downloadUrl = window.baseUrl + '/api/liveeventical';
    window.open(downloadUrl);
  }
}

class SocialPanel {
  init () {
    this.socialPanelWidth = 240; //should match style in variables.less
    this.mainContent = $('.main-content');
    this.header = $('#bc-main-header');
    this.nonSocialContent = $('#bc-main-header').find('.non-social-panel-content');
    this.socialPanel = $('#social-panel');
    this.socialPanelControlsInNav = $('.social-panel-display');
    this.videoWrap = $('.video-wrap');

    //only execute this if social panel exists
    if ($('#social-panel').length > 0) {
      $('#bc-site-container').addClass('social-panel-present');

      this.configurePanels();
      bcLiveEvent.mimicBackgroundCover(bcLiveEvent.$elementsThatMimic, bcLiveEvent.$parentOfMimicer, bcLiveEvent.$mimicWrapper);
      this.toggleSocialPanel();
      this.addFooterCloneForMobile();
    }
  }

  configurePanels () {//squish the main content to make room for the social panel
    if (!this.socialPanelControlsInNav) {
      return;
    }
    const windowWidth = bcLiveEvent.windowWidth();
    const remainingWidth = windowWidth - this.socialPanelWidth;

    //dummy div is used to determine when the browser has switched over into social flop mode. It is equal to 1 if twitter has been collapsed and not set otherwise
    if ($('#media-query-dummy-div').css('z-index') !== '1') {
      if (this.socialPanelControlsInNav.hasClass('has-social')) {
        if (!this.socialPanelControlsInNav.hasClass('inactive')) {
          this.mainContent.width(remainingWidth);
          //this.videoWrap.width(remainingWidth);

        } else {
          this.mainContent.width('100%');
        }
        this.nonSocialContent.width(remainingWidth);
      }
    } else {
      this.mainContent.width('100%');
      this.nonSocialContent.width('100%');
    }

  }

  toggleSocialPanel () {
    $('.social-panel-display .show-and-hide .show-feed').click(() => {
      this.setFeedOpen(true);
    });

    $('.social-panel-display .show-and-hide .hide-feed').click(() => {
      this.setFeedOpen(false);
    });
  }

  setFeedOpen (isOpen) {
    const toMainContentWidth = isOpen ? (bcLiveEvent.windowWidth() - this.socialPanelWidth) : '100%';
    const toSocialPanelWidth = isOpen ? this.socialPanelWidth : '0px';

    this.mainContent.animate({
      width: toMainContentWidth,
    }, {
      complete: () => {
        if (isOpen) {
          this.socialPanelControlsInNav.removeClass('inactive');
        } else {
          this.socialPanelControlsInNav.addClass('inactive');
        }
        bcLiveEvent.executeOnResize();
      },
    });

    this.socialPanel.animate({
      width: toSocialPanelWidth,
    }, {
      step: function () {
        bcLiveDetail.adjustChatrollWidth();
        bcLiveDetail.adjustVideoDetailWidth();
        bcLiveEvent.mimicBackgroundCover(bcLiveEvent.$elementsThatMimic, bcLiveEvent.$parentOfMimicer, bcLiveEvent.$mimicWrapper);
      },
      complete: function () {
        bcLiveDetail.adjustChatrollWidth();
        bcLiveDetail.adjustVideoDetailWidth();
        bcLiveEvent.mimicBackgroundCover(bcLiveEvent.$elementsThatMimic, bcLiveEvent.$parentOfMimicer, bcLiveEvent.$mimicWrapper);
      },
    });
  }

  addFooterCloneForMobile () {
    $('#social-panel').after($('#bc-main-footer').clone().removeClass('original').addClass('cloned'));
  }
}

class Subscribe {
  init () {
    $('#live-submit-email').on('click', function (e) {
      e.preventDefault();
      this.submitLead();
    });
  }

  submitLead () {
    // mimic lead form submission, see layout.hbs for more details
    const lead = {
      data: {
        'First Name': '',
        'Last Name': '',
        'Email': $('#live-input-email').val(),
      },
      'sentByGallery': true,
      'sendToAudience': true,
    };

    const $iframe = $('#liveEventLeadIframe');
    if ($iframe && $iframe[0]) {
      $iframe[0].contentWindow.postMessage(lead, window.studioUrl);
    }

    // showing success for now no matter the case
    this.onLeadSubmitted();
  }

  onLeadSubmitted () {
    this.showLeadSubmitted(true, true);

    // clear input while success showing
    $('#live-input-email').val('');

    setTimeout(() => {
      this.showLeadSubmitted(false);
    }, 1000);
  }

  showLeadSubmitted (isSubmitted, skipAnimation) {
    const $message = $('#live-email-signup-success');
    if (isSubmitted) {
      $message.fadeIn(skipAnimation ? 0 : 300);
    } else {
      $message.fadeOut(skipAnimation ? 0 : 300);
    }
  }
}

class Header {
  init () {
    this.stickyNavControlTimeout = 0;
    this.MOBILE_FONT_SIZE = 15;
    const options = {
      liveEventHeader: $('#bc-main-header'),
      liveEventHeaderInner: $('#bc-main-header .main-header-inner'),
      navOffset: $('#bc-main-header').offset().top,
      navHeight: $('#bc-main-header').height(),
      twitterBoxSon: $('#social-panel .twitter-content'),
    };
    this.customHeaderControl(options);
    this.stickyNavControl(options);
    this.mobileDropdown();
    this.listenForTwitter(options);
    this.resizeFonts();
    $('.navbar-ex1-modal').on('shown.bs.modal', bcCarousel.stopPlayback);
  }

  resizeFonts () {
    if (!bcLiveEvent.isMobile()) {
      return;
    }
    const richTextIds = [
      '.bc-mobile-home',
      '.navbar-static-top-mobile .main-header-logo',
      '.footer-logo-mobile',
    ];
    const richTextElements = richTextIds.map(id => document.querySelector(id));

    richTextElements.map(element =>
      updateTextSize(element, this.MOBILE_FONT_SIZE, this.MOBILE_FONT_SIZE - 1, this.MOBILE_FONT_SIZE + 1)
    );

  }
  onDisqusReady () {
    // nothing required for now
  }

  customHeaderControl (options) {
    $(window).scroll(() => {
      this.stickyNavControl(options);
    });
  }

  stickyNavControl (options) {
    const windowOffset = $(window).scrollTop();
    const windowHeight = $(window).height();
    const customFooterHeight = $('.customFooterContainer').outerHeight(true);

    let customFooterHeightAdjust = 0;
    if (customFooterHeight >= 0) {
      customFooterHeightAdjust = customFooterHeight + (windowOffset - ($(document).innerHeight() - windowHeight));
      if (customFooterHeightAdjust < 0) {
        customFooterHeightAdjust = 0;
      }
    }

    const liveOffset = options.navOffset - windowOffset;
    const forcedTwitterHeight = $(window).height() - liveOffset;

    if (bcSocialPanel.socialPanel && bcSocialPanel.socialPanel.width() === 0 && bcLiveEvent.isMobile()) {
      options.twitterBoxSon.css({ height: '0px' } );
      return;
    }

    if (liveOffset < 0) {
      options.liveEventHeaderInner.css({ position: 'fixed' });
      options.twitterBoxSon.css({ height: windowHeight - customFooterHeightAdjust, top: 0 });
    } else {
      options.liveEventHeaderInner.css({ position: 'relative' });
      options.twitterBoxSon.css({ height: forcedTwitterHeight - customFooterHeightAdjust, top: liveOffset });
    }
  }

  mobileDropdown () {
    const mobileNavButton = $('header.main-header .mobile-nav-arrow');
    const mobileNavDropdown = $('#bc-main-header-links');

    mobileNavButton.click(function () {
      if (mobileNavButton.hasClass('active')) {
        mobileNavDropdown.slideUp();
        mobileNavButton.bcSuperSpin(180, 0, 300);
        //
        mobileNavButton.removeClass('active');
        mobileNavDropdown.removeClass('active');
      } else {
        mobileNavDropdown.slideDown();
        mobileNavButton.bcSuperSpin(0, 180, 300);
        //
        mobileNavButton.addClass('active');
        mobileNavDropdown.addClass('active');
      }
    });
  }

  listenForTwitter (options) {
    if (typeof global.twttr !== 'undefined') {
      global.twttr.ready(
        (twttr) => {
          twttr.events.bind(
            'loaded',
            () => {
              this.stickyNavControl(options);
            }
          );
        }
      );
    }
  }
}

class BCHERO {
  init () {
    // expects timestamp to be generated in UTC by layout.hbs
    this.countdownInterval = 0;
    this.initiateCountdown(bcLiveEvent.EVENT_DETAILS.timestamp);
  }

  initiateCountdown (timestamp) {
    const liveEventDateAsObj = bcLiveEvent.convertForTimezone(timestamp);

    const isMobileCountdown = (countdownCells) => countdownCells.length > 5;

    const countdownCells = $('.countdown table tr.countdown-numbers td');

    const countdownParams = {
      months: $(countdownCells[0]),
      days: $(countdownCells[1]),
      hours: $(countdownCells[2]),
      minutes: $(countdownCells[3]),
      seconds: $(countdownCells[4]),
    };

    let countdownParamsMobile = null;
    if (isMobileCountdown(countdownCells)) {
      countdownParamsMobile = {
        months: $(countdownCells[5]),
        days: $(countdownCells[6]),
        hours: $(countdownCells[7]),
        minutes: $(countdownCells[8]),
        seconds: $(countdownCells[9]),
      };
    }

    this.countdownInterval = setInterval( () => {
      if ((new Date()).getTime() > timestamp) {
        const $countdown = $('.countdown');

        if ($countdown.hasClass('in-secondary-hero')) {
          $countdown.css('visibility', 'hidden');
          $('.mobile-detail-counter').hide();
        } else {
          $countdown.addClass('finished');
        }

        clearInterval(this.countdownInterval);
        return;
      }

      const liveEventDate = moment(liveEventDateAsObj);
      const now = moment(new Date());
      const duration = moment.duration(liveEventDate.diff(now));
      const liveCountdown = {};
      liveCountdown.years = duration.years();
      liveCountdown.months = duration.months();
      liveCountdown.days = duration.days();
      liveCountdown.hours = duration.hours();
      liveCountdown.minutes = duration.minutes();
      liveCountdown.seconds = duration.seconds();
      this.setCountdown(countdownParams, liveCountdown);
      if (isMobileCountdown(countdownCells)) {
        this.setCountdown(countdownParamsMobile, liveCountdown);
      }
    }, 1000);

    // elements start hidden b/c of CSS to avoid 'seeing' the non-initialzed version,
    // so we 'fade' them here
    //Do NOT show counter for post event pages
    if (window.site.indexPageReplace !== 'index-post') {
      setTimeout(function () {
        $('.countdown').find('.countdown-clock, .countdown-finished-message, .countdown-no-video-message').animate({
          opacity: 1,
        }, {
          duration: 200,
        });
        //show mobile counter as well only if needed
        if ((new Date()).getTime() < timestamp) {
          $('.mobile-detail-counter').show();
          $('.category .navbar-inner .navbar-brand, #custom-accordion').css('margin-bottom', '50px');
          $('.detail .navbar-inner .navbar-brand, #custom-accordion').css('margin-bottom', '50px');
        }
      }, 1000);
    } else {
      // remove mobile countdown completely and adjust header height
      $('.mobile-detail-counter').css('visibility', 'hidden');
    }
  }

  setCountdown (countdownParams, liveCountdown) {

    // Mute empty cells
    if (liveCountdown.months === 0) {
      countdownParams.months.css({ opacity: .5 });
      if (liveCountdown.days === 0) {
        countdownParams.days.css({ opacity: .5 });
        if (liveCountdown.hours === 0) {
          countdownParams.hours.css({ opacity: .5 });
          if (liveCountdown.minutes === 0) {
            countdownParams.minutes.css({ opacity: .5 });
            if (liveCountdown.seconds === 0) {
              countdownParams.seconds.css({ opacity: .5 });
            }
          }
        }
      }
    }
    countdownParams.months.text(bcLiveEvent.twoDigitsPlease(liveCountdown.years * 12 + liveCountdown.months));
    countdownParams.days.text(bcLiveEvent.twoDigitsPlease(liveCountdown.days));
    countdownParams.hours.text(bcLiveEvent.twoDigitsPlease(liveCountdown.hours));
    countdownParams.minutes.text(bcLiveEvent.twoDigitsPlease(liveCountdown.minutes));
    countdownParams.seconds.text(bcLiveEvent.twoDigitsPlease(liveCountdown.seconds));
  }
}

class SecondaryNav {
  init () {
    $('nav.main-nav ul li .trigger-dropdown').click((e) => {
      const $this = $(e.target);
      $this.siblings('ul').toggleClass('show-dropdown hide-dropdown');
      $this.parent().toggleClass('active-dropdown');
    });

    $('#bc-category-list-mobile ul li .trigger-dropdown').click((e) => {
      const $this = $(e.currentTarget);
      $this.siblings('ul').toggleClass('show-dropdown hide-dropdown');
      $this.parent().toggleClass('active-dropdown');
    });
  }
}

class Carousel {
  init () {
    this.VIDEO_ASPECT_RATIO = 16 / 9;
    this.VIDEO_ASPECT_WIDTH = 16;
    this.VIDEO_ASPECT_HEIGHT = 9;
    this.currentVideo = null;
    this.currentVideoSlideIndex = -1;
    this.templates = [ ];
    this.resizeTimeout = 0;

    const slickOptions = {
      dots: true,
      infinite: true,
      draggable: true,
      centerMode: true,
      centerPadding: '15px',
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      onAfterChange: () => { this.onAfterSlideChange(); },
    };

    if ($('.home-carousel').children().length === 1) {
      slickOptions.infinite = false;
    }

    $('.home-carousel').slick(slickOptions);

    $('.home-carousel').on('swipe', () => {
      this.stopPlayback();
    });

    bcLiveEvent.imageLoader.loadImages();
    bcLiveEvent.imageLoader.loaded = () => {
      bcCarousel.onWindowResize();
      bcLiveEvent.setThumbnailHeight();
    };

  }

  resizeVideos () {
    $('.home-carousel-slide').each((key, value) => {
      const $this = $(value);
      const $videoContainer = $this.find('.home-carousel-video');
      $videoContainer.css('height', '');
      $videoContainer.css('width', '');
      const $video = $this.find('.bc-player');
      let width = $videoContainer.innerWidth();
      width = Math.floor(width / this.VIDEO_ASPECT_WIDTH) * this.VIDEO_ASPECT_WIDTH;
      const height = Math.floor(width / this.VIDEO_ASPECT_RATIO);
      $video.innerHeight(height + 'px');
      $video.innerWidth(width + 'px');

      const $image = $this.find('.bc-image-loader');
      $image.innerHeight(height + 'px');
    });
  }

  positionCarouselDots () {
    const $carousel = $('.home-carousel');
    let position;

    if (bcLiveEvent.isMobile()) {
      position = $carousel.find('.home-carousel-video').height() - 20;
    } else if (bcLiveEvent.mq === 600) {
      position = $carousel.find('.home-carousel-video').height() + 25;
    } else {
      position = $carousel.find('.home-carousel-video').height() + 50;
    }

    $carousel.find('.slick-dots').css({
      bottom: 'auto',
      top: position,
    });
  }

  stopPlayback () {
    if (window.videojs && window.videojs.players &&
      window.videojs.players.performPlayer) {
      window.videojs.players.performPlayer.pause();
    }
  }

  loadTemplate (url, callback) {
    if (this.templates[url]) {
      return callback(this.templates[url]);
    }

    $.ajax({
      url: url,
      success: (contents) => {
        this.templates[url] = window.bcGallery.Handlebars.compile(contents);
        callback(this.templates[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  onWindowResize () {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.resizeVideos();// order matters
      this.positionCarouselDots();// order matters
    }, 100);

    bcDetail.updateReadMore();
  }
}

const bcCategoriesNav = new CategoriesNav();
const bcDetail = new Detail();
const bcLiveDetail = new LiveDetail();
const bcLiveEvent = new LiveEvent();
const bcNextVideo = new NextVideo();
const bcRemindMe = new RemindMe();
const bcSocialPanel = new SocialPanel();
const bcSubscribe = new Subscribe();
const bcHeader = new Header();
const bcHero = new BCHERO();
const bcSecondaryNav = new SecondaryNav();
const bcCarousel = new Carousel();

$(document).ready(function () {
  bcLiveEvent.init();
  bcCategoriesNav.init();
  bcHeader.init();
  bcDetail.init();
  bcHero.init();
  bcSocialPanel.init();
  bcRemindMe.init();
  bcSubscribe.init();
  bcLiveDetail.init();
  bcNextVideo.init();
  bcSecondaryNav.init();
  bcCarousel.init();
});

// Discus plugin expects this function.
function disqus_config () { //eslint-disable-line no-unused-vars
  this.callbacks.onReady.push(function () {
    bcDetail.onDisqusReady();
    bcHeader.onDisqusReady();
  });
}

window.BCLS = window.BCLS || {};
//SmartPlayer auto-start in live mode.
window.BCLS.afterReadyHandler = function () {
  if (window.site.indexPageReplace === 'index-live') { //Start the video if we are in live mode
    window.BCLS.modVP.play();
    bcLiveEvent.executeOnResize();
  }
};
