/**
 * @file Functions for resizing fonts within html (used for rich text)
 */

/**
 * Gets the max number in the list ignoring null values
 */
function maxNonNull (...args) {
  return Math.max.apply(null, args.filter(arg => arg != null));
}

/**
 * Gets the min number in the list ignoring null values
 */
function minNonNull (...args) {
  return Math.min.apply(null, args.filter(arg => arg != null));
}

/**
 * Gets the font size of a given element (is an approximation which is appropriate for our use-case)
 *
 * @param {Element} element HTML element
 * @param {number} baseFontSize Base font size to use if no font size is specified on the element
 * @returns {number} Element's font size
 */
function getFontSize (element, baseFontSize) {
  const fontSize = parseFloat(element.style.fontSize || baseFontSize);

  //Adjust font sizes for header elements (use multipliers from text.less)
  switch (element.tagName) {
    case 'H1': return fontSize * 2;
    case 'H2': return fontSize * 1.5;
    case 'H3': return fontSize * 1.2;
    default: return fontSize;
  }
}

/**
 * Gets the min and max font sizes recursively inside the given HTML element
 *
 * @param {Element} parent Parent HTML element
 * @param {number} baseFontSize Base font size to use
 * @returns {number[]} Tuple containing the min font size and max font size
 */
function getMinMaxFontSizes (parent, baseFontSize) {
  let maxActiveFontSize = null;
  let minActiveFontSize = null;

  //Get the font size of the element. This font size applies to all child text nodes and and elements inside of the
  //parent, so we only need to calculate it once. It is included in the min/max calculation if this element directly
  //contains text nodes, and is passed as the baseFontSize for calculating the min/max font sizes of child elements.
  const fontSize = getFontSize(parent, baseFontSize);

  for (const node of parent.childNodes) {
    if (node.nodeType === Node.TEXT_NODE) {
      //This element contains text directly, so include the current font size in the min / max calculation
      minActiveFontSize = minNonNull(minActiveFontSize, fontSize);
      maxActiveFontSize = maxNonNull(maxActiveFontSize, fontSize);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      //Recursively get the min / max font sizes of child elements
      const [min, max] = getMinMaxFontSizes(node, fontSize);

      maxActiveFontSize = maxNonNull(maxActiveFontSize, max);
      minActiveFontSize = minNonNull(minActiveFontSize, min);
    }
  }
  return [minActiveFontSize, maxActiveFontSize];
}

/**
 * Sets the font sizes of elements inside the given HTML element based on a function
 * @param {Element} parent Parent HTML element [will be mutated]
 * @param {number} baseFontSize Base font size to use
 * @param {function(number): number} resizeFunc Function which takes in the current font size and returns the new font
 *                                              size
 */
function setFontSizes (parent, baseFontSize, resizeFunc) {
  for (const node of parent.children) {
    const fontSize = getFontSize(node, baseFontSize);
    const newFontSize = resizeFunc(fontSize);

    node.style.fontSize = `${newFontSize}px`;
    setFontSizes(node, fontSize, resizeFunc);
  }
}

/**
 * Gets a function for performing resizing on the text of an element
 * @param {Element} parent Parent HTML Element
 * @param {number} baseFontSize Base font size to use
 * @param {number} minFontSize Minimum font size
 * @param {number} maxFontSize Maximum font size
 * @returns {function(number): number|null} Font resizing function or null
 */
function getFontResizeFunction (parent, baseFontSize, minFontSize, maxFontSize) {
  //If we don't have a minimum or a maximum size, or if our min and max are the same, we can't do any scaling.  Simply
  //ensure the min/max size is adhered to
  if (minFontSize === maxFontSize) {
    return () => minFontSize;
  } else if (!minFontSize) {
    return fontSize => minNonNull(fontSize, maxFontSize);
  } else if (!maxFontSize) {
    return fontSize => maxNonNull(fontSize, minFontSize);
  }

  //Get the min and max size from our element
  const [minActiveFontSize, maxActiveFontSize] = getMinMaxFontSizes(parent, baseFontSize);

  if (maxActiveFontSize !== minActiveFontSize) {
    //Scale the fonts to be within the sizes
    const fontScale = (maxFontSize - minFontSize) / (maxActiveFontSize - minActiveFontSize);
    return fontSize => (fontSize - minActiveFontSize) * fontScale + minFontSize;
  } else if (maxActiveFontSize > maxFontSize) {
    return () => maxFontSize;
  } else if (minActiveFontSize < minFontSize) {
    return () => minFontSize;
  }
}
/**
 * Scales the font sizes of elements inside the given HTML element using a minimum and maximum font size.  Mutates the
 * element passed in.
 *
 * @param {Element} parent Parent HTML element [will be mutated]
 * @param {number} baseFontSize Base font size to use
 * @param {number} minFontSize Minimum font size
 * @param {number} maxFontSize Maximum font size
 */
function updateTextSize (parent, baseFontSize, minFontSize, maxFontSize) {
  if (!parent || !baseFontSize || !(minFontSize || maxFontSize)) {
    //Can't perform any resizing if the parent element or base font size are null, and we need either a min or max
    //size (or both)
    return;
  }

  const resizeFunc = getFontResizeFunction(parent, baseFontSize, minFontSize, maxFontSize);
  if (resizeFunc) {
    setFontSizes(parent, baseFontSize, resizeFunc);
  }
}

module.exports = {
  updateTextSize,
};
